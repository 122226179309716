import { InformationCircleIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/16/solid';
import toast from 'react-hot-toast';

type Props = {
  type: 'info' | 'success' | 'error';
  content: string;
};

export function useToast() {
  return ({ type, content }: Props) =>
    toast(content, {
      position: 'bottom-center',
      duration: 2000,
      className: 'w-full h-[40px]',
      icon:
        type === 'info' ? (
          <InformationCircleIcon className="fill-zinc-600 w-[20px]" />
        ) : type === 'success' ? (
          <CheckCircleIcon className="fill-emerald-600 w-[20px]" />
        ) : type === 'error' ? (
          <ExclamationCircleIcon className="fill-red-600 w-[20px]" />
        ) : null,
    });
}
