import axios from 'axios';

import { config } from '@/config';
import { useToast } from '@/hook';
import { ResWithSuccess } from '@/type';

type Props = {
  musicLibraryId: number;
  onSuccess: () => void;
};

export function DeleteMusicFromLibraryContainer({ musicLibraryId, onSuccess }: Props) {
  const toast = useToast();

  const handleClickRemoveMusicAtLibrary = async () => {
    try {
      await axios.delete(`${config.api.host}/musics-libraries/${musicLibraryId}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(config.token.name.access)}`,
        },
      });
      onSuccess();

      toast({ type: 'success', content: '플레이 리스트에서 제거 되었습니다.' });
    } catch (error) {
      toast({ type: 'error', content: '플레이 리스트에서 제거 실패하였습니다.' });
      console.log(error);
    }
  };

  return <div onClick={handleClickRemoveMusicAtLibrary}>재생목록에서 제거</div>;
}
