import { useState } from 'react';
import useSWR from 'swr';
import tw from 'tailwind-styled-components';
import { EllipsisVerticalIcon } from '@heroicons/react/16/solid';
import axios from 'axios';

import { CreateLibraryContainer, DeleteMusicContainer, GetAndPostYoutubeMusicContainer } from '@/container/music';
import { LibraryItem, MusicItem, ResWithSuccess } from '@/type';
import { useMusicStore } from '@/store';
import { useToast } from '@/hook';
import { config } from '@/config';

import { MusicScrollLayout } from '@/layout';
import { Dialog, DialogBody } from '@/component/basic/dialog';
import { Checkbox, CheckboxField, CheckboxGroup } from '@/component/basic/checkbox';
import { Label } from '@/component/basic/fieldset';
import { MusicListItem } from '@/component/custom';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '@/component/basic/dropdown';

const Container = tw.div`
  relative
  flex flex-col
  w-full h-full
`;

export function MusicListPage() {
  const { setCurrentPlayList, selectedMusicIndex, setSelectedMusicIndex, setCurrentLibrary, currentLibrary } =
    useMusicStore();
  const { data, error, mutate } = useSWR<ResWithSuccess<MusicItem[]>>('/musics', {
    revalidateOnFocus: true,
  });

  const [selectedMusicItem, setSelectedMusicItem] = useState<MusicItem | null>(null);
  const [alertMusicItem, setAlertMusicItem] = useState<MusicItem | null>(null);

  const handleSelectMusicItem = (musicIndex: number) => () => {
    setCurrentPlayList(data?.data ?? []);
    setSelectedMusicIndex(musicIndex);
    setCurrentLibrary({
      title: '전체',
      route: '/musics',
    });
  };

  if (error) {
    return <div className="px-4 flex w-full h-full justify-center items-center text-sm">Failed to load 😂</div>;
  }

  return (
    <Container>
      <DeleteMusicContainer
        alertMusicItem={alertMusicItem}
        closeAlert={() => setAlertMusicItem(null)}
        onSuccess={() => mutate()}
      />
      {selectedMusicItem && (
        <PatchMusicLibraryStatusDialog
          selectedMusicItem={selectedMusicItem}
          closeDialog={() => setSelectedMusicItem(null)}
        />
      )}
      <GetAndPostYoutubeMusicContainer onSuccessPostMusic={() => mutate()} />
      <MusicScrollLayout>
        {data?.data.map((musicItem, i) => (
          <MusicListItem
            key={musicItem.videoId}
            isSelected={currentLibrary?.route === '/musics' && i === selectedMusicIndex}
            item={musicItem}
            libraries={
              <div className="flex flex-row gap-4">
                {musicItem.musicLibraries.map(musicLibrary => (
                  <div
                    key={musicLibrary.id}
                    className="flex flex-row gap-1 items-center rounded-lg px-1"
                    style={{ background: musicLibrary.library.color }}
                  >
                    <div className="text-[10px] text-white">{musicLibrary.library.title}</div>
                  </div>
                ))}
              </div>
            }
            onClickItem={handleSelectMusicItem(i)}
            dropDownMenu={
              <Dropdown>
                <DropdownButton plain aria-label="More options" className="w-[30px] h-[30px]">
                  <EllipsisVerticalIcon />
                </DropdownButton>
                <DropdownMenu>
                  <DropdownItem onClick={() => setSelectedMusicItem(musicItem)}>재생목록 추가</DropdownItem>
                  <DropdownItem onClick={() => setAlertMusicItem(musicItem)}>삭제</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            }
          />
        ))}
      </MusicScrollLayout>
    </Container>
  );
}

const PatchMusicLibraryStatusDialog = ({
  selectedMusicItem,
  closeDialog,
}: {
  selectedMusicItem: MusicItem;
  closeDialog: () => void;
}) => {
  const toast = useToast();
  const { data, mutate } = useSWR<ResWithSuccess<LibraryItem[]>>('/libraries');

  const onPostMusicLibrary = async (library: LibraryItem) => {
    try {
      await axios.post<ResWithSuccess<MusicItem>>(
        `${config.api.host}/musics-libraries`,
        {
          libraryId: library.id,
          musicId: selectedMusicItem.id,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem(config.token.name.access)}`,
          },
        }
      );
      mutate();
      toast({ type: 'success', content: '추가되었습니다.' });
    } catch (error) {
      console.log(error);
    }
  };

  const onDeleteMusicLibrary = async (musicLibraryId: number) => {
    try {
      await axios.delete(`${config.api.host}/musics-libraries/${musicLibraryId}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(config.token.name.access)}`,
        },
      });
      mutate();
      toast({ type: 'success', content: '제외되었습니다.' });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog open={true} onClose={closeDialog}>
      <DialogBody>
        <div className="flex flex-row items-center p-2 gap-4 bg-zinc-100 rounded-md mb-2">
          <img src={selectedMusicItem.thumbnailUrl} alt="" className="w-[50px] aspect-square" />
          <div className="flex flex-col justify-center gap-1 flex-1 h-full">
            <div className="w-full truncate-multiline text-xs font-semibold">{selectedMusicItem.title}</div>
            <div className="w-full text-xs text-zinc-400">{selectedMusicItem.author}</div>
          </div>
        </div>
        <CheckboxGroup className="max-h-[30vh] overflow-y-scroll py-4 px-2">
          {data?.data.length ? (
            data?.data.map(library => (
              <CheckboxField key={library.id}>
                <Checkbox
                  name={library.title}
                  value={library.title}
                  defaultChecked={
                    !!library.musicLibraries.find(musicLibrary => musicLibrary.music.id === selectedMusicItem.id)
                  }
                  onChange={isChecked =>
                    isChecked
                      ? onPostMusicLibrary(library)
                      : onDeleteMusicLibrary(
                          library.musicLibraries.find(musicLibrary => musicLibrary.music.id === selectedMusicItem.id)
                            ?.id!
                        )
                  }
                />
                <Label>{library.title}</Label>
              </CheckboxField>
            ))
          ) : (
            <div className="flex flex-col w-full">
              <div className="text-sm">재생목록이 없습니다. 재생목록을 추가해주세요❤️</div>
            </div>
          )}
        </CheckboxGroup>
        <div className="mt-2">
          <CreateLibraryContainer onSuccess={() => mutate()} />
        </div>
      </DialogBody>
    </Dialog>
  );
};
