import { useState } from 'react';
import axios from 'axios';

import { config } from '@/config';
import { useToast } from '@/hook';

import { Alert, AlertActions, AlertTitle } from '@/component/basic/alert';
import { Button } from '@/component/basic/button';
import { Loading } from '@/component/custom';

import type { MusicItem, ResWithSuccess } from '@/type';

type Props = {
  alertMusicItem: MusicItem | null;
  closeAlert: () => void;
  onSuccess: () => void;
};

const removeNumberFromAllArrays = (
  obj: { [key: string]: number[] },
  numToRemove: number
): { [key: string]: number[] } => {
  const newObj: { [key: string]: number[] } = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = obj[key].filter(number => number !== numToRemove);
    }
  }

  return newObj;
};

export function DeleteMusicContainer({ alertMusicItem, closeAlert, onSuccess }: Props) {
  const toast = useToast();

  const [isLoading, setLoading] = useState(false);

  const handleClickDeleteMusic = async () => {
    setLoading(true);

    if (!alertMusicItem) return;

    try {
      const { data } = await axios.delete<ResWithSuccess<boolean>>(`${config.api.host}/musics/${alertMusicItem.id}`);
      if (data.data) {
        onSuccess();
        const musicList = localStorage.getItem('musicList');

        if (musicList) {
          const parsedMusicList = JSON.parse(musicList);
          const filteredObj = removeNumberFromAllArrays(parsedMusicList, alertMusicItem.id);

          localStorage.setItem('musicList', JSON.stringify(filteredObj));
        }

        toast({ type: 'success', content: '노래를 삭제하였습니다.' });
      }
    } catch (error) {
      toast({ type: 'error', content: '노래를 삭제에 실패하였습니다.' });
      console.log(error);
    } finally {
      setLoading(false);
      closeAlert();
    }
  };

  return (
    <div>
      <Alert open={!!alertMusicItem} onClose={() => {}}>
        {isLoading ? (
          <div className="absolute top-0 right-0 bottom-0 left-0 bg-white/05 backdrop-blur-[2px] rounded-2xl flex justify-center items-center z-[999]">
            <Loading />
          </div>
        ) : null}
        <AlertTitle>{`'${alertMusicItem?.title}' 노래를 정말 삭제하시겠습니까?`}</AlertTitle>
        <AlertActions>
          <Button plain onClick={closeAlert}>
            취소
          </Button>
          {!!alertMusicItem && <Button onClick={handleClickDeleteMusic}>삭제</Button>}
        </AlertActions>
      </Alert>
    </div>
  );
}
