import tw from 'tailwind-styled-components';

import { Loading } from '@/component/custom';

const Container = tw.div`
  absolute left-0 right-0 top-0 bottom-0
  flex justify-center items-center
  w-full h-full
  bg-white/20
  z-[998]
`;

export function LoadingBox() {
  return (
    <Container>
      <Loading />
    </Container>
  );
}
