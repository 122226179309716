import useSWR from 'swr';
import tw from 'tailwind-styled-components';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { useLibraryLikeListStore, useMusicStore } from '@/store';
import { useAuth } from '@/hook';

import { CreateLibraryContainer } from '@/container/music';
import { MusicScrollLayout } from '@/layout';
import { LibraryListItem } from '@/component/custom';

import { AuthorityLevel, type LibraryItem, type ResWithSuccess } from '@/type';

const Container = tw.div`
  flex flex-col
  w-full h-full
`;

export function LibraryListsPage() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { currentLibrary, selectedMusicIndex } = useMusicStore();
  const { data, mutate } = useSWR<ResWithSuccess<LibraryItem[]>>(`/libraries`);
  const [isLikeList, setIsLikeList] = useState(false);
  const { likeList, toggleLike } = useLibraryLikeListStore();

  const handleSuccessCreateLibrary = (createLibrary: LibraryItem) => {
    if (!data?.data) return;
    data?.data && mutate({ data: [createLibrary, ...data.data] });
  };

  return (
    <Container>
      <div className="flex flex-row w-full h-[40px] text-sm">
        <div
          className={`flex justify-center items-center flex-1 border-b ${
            isLikeList ? 'bg-zinc-200 opacity-40 border-white' : 'bg-white border-zinc-200'
          }`}
          onClick={() => setIsLikeList(false)}
        >
          All
        </div>
        <div
          className={`flex justify-center items-center flex-1 border-b  ${
            isLikeList ? 'bg-white border-zinc-200' : 'bg-zinc-200 opacity-40 border-white'
          }`}
          onClick={() => setIsLikeList(true)}
        >
          Likes
        </div>
      </div>
      <MusicScrollLayout>
        {isLikeList ? (
          !likeList.length ? (
            <div className="px-4 h-full flex justify-center items-center text-sm">
              좋아요 목록이 없습니다. 추가해주세요.
            </div>
          ) : (
            data?.data
              .filter(libraryItem => likeList.includes(libraryItem.id))
              .map(libraryItem => (
                <LibraryListItem
                  key={libraryItem.id}
                  libraryItem={libraryItem}
                  isPlaying={currentLibrary?.route === `/musics/libraries/${libraryItem.id}`}
                  isLiked={likeList.includes(libraryItem.id)}
                  toggleLike={() => toggleLike(libraryItem.id)}
                  onClickItem={() => navigate(`/musics/libraries/${libraryItem.id}`)}
                />
              ))
          )
        ) : data?.data.length ? (
          data?.data.map(libraryItem => (
            <LibraryListItem
              key={libraryItem.id}
              libraryItem={libraryItem}
              isPlaying={currentLibrary?.route === `/musics/libraries/${libraryItem.id}`}
              isLiked={likeList.includes(libraryItem.id)}
              toggleLike={() => toggleLike(libraryItem.id)}
              onClickItem={() => navigate(`/musics/libraries/${libraryItem.id}`)}
            />
          ))
        ) : (
          <div className="px-4 h-full flex justify-center items-center text-sm">
            재생목록이 없습니다. 재생목록을 추가해주세요.
          </div>
        )}
      </MusicScrollLayout>
      {selectedMusicIndex === null && auth?.authority.level === AuthorityLevel.SUPER_ADMIN && (
        <div className="flex px-4 py-8">
          <CreateLibraryContainer onSuccess={handleSuccessCreateLibrary} />
        </div>
      )}
    </Container>
  );
}
