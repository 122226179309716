import { useEffect } from 'react';

import { Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { useLibraryLikeListStore } from '@/store';
import { PWAInstallPromptContainer } from '@/container/common';
import { AudioPlayerContainer } from '@/container/music';

export function RootLayout() {
  const { init: initLibraryLikeList } = useLibraryLikeListStore();

  useEffect(() => {
    initLibraryLikeList();
  }, [initLibraryLikeList]);

  return (
    <div className={`flex justify-center items-center w-screen h-svh`}>
      <PWAInstallPromptContainer />
      <div className="flex flex-col items-center w-full h-full">
        <div className="relative flex flex-col max-w-[700px] w-full h-full">
          <Toaster />
          <Outlet />
          <AudioPlayerContainer />
        </div>
      </div>
    </div>
  );
}
