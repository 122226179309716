import { useState } from 'react';
import { XCircleIcon } from '@heroicons/react/16/solid';

import { Button } from '@/component/basic/button';

import { type YoutubeInfo } from '@/type';

type Props = {
  youtubeInfo: YoutubeInfo;
  closeItem: () => void;
  postMusic: () => void;
};

export function YoutubeInfoItem({ youtubeInfo, closeItem, postMusic }: Props) {
  const [isImageLoaded, setImageLoaded] = useState(false);

  return (
    <div className={`${isImageLoaded ? 'h-fit' : 'h-0'} flex flex-col`}>
      <div className="flex justify-center items-center py-2">
        <XCircleIcon className="w-[30px] fill-zinc-400 cursor-pointer" onClick={closeItem} />
      </div>
      <img
        src={youtubeInfo.thumbnail_url}
        alt=""
        style={{ width: '100%', height: `${(youtubeInfo.width / youtubeInfo.height) * 100}%` }}
        className="rounded-md"
        onLoad={() => setImageLoaded(true)}
      />
      <div className="flex flex-col w-full py-4 gap-1">
        <p className="truncate text-black font-semibold">{youtubeInfo.title}</p>
        <p className="truncate text-zinc-500 text-xs">{youtubeInfo.author_name}</p>
      </div>
      <Button onClick={postMusic}>추가하기</Button>
    </div>
  );
}
