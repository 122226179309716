import { useState } from 'react';
import axios from 'axios';
import { TrashIcon } from '@heroicons/react/16/solid';

import { useToast } from '@/hook';
import { config } from '@/config';

import { Alert, AlertActions, AlertDescription, AlertTitle } from '@/component/basic/alert';
import { LoadingBox } from '@/component/custom';
import { Badge } from '@/component/basic/badge';
import { Button } from '@/component/basic/button';

import { AuthorityLevel, AuthorityModel, type ResWithSuccess } from '@/type';

export const DeleteAuthorityContainer = ({
  authority,
  onSuccess,
}: {
  authority: AuthorityModel;
  onSuccess: () => void;
}) => {
  const toast = useToast();
  const [isAlertopen, setAlertOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleClickCloseAlert = () => {
    if (isLoading) return;
    setAlertOpen(false);
  };

  const handleClickDelete = async () => {
    setLoading(true);

    try {
      const { data } = await axios.delete<ResWithSuccess<boolean>>(`${config.api.host}/authority/${authority.id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(config.token.name.access)}`,
        },
      });

      if (data.data) {
        onSuccess();
        setAlertOpen(false);
        toast({ content: '삭제 성공하였습니다.', type: 'success' });
      }
    } catch (error) {
      toast({ content: '삭제에 실패하였습니다.', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Alert className="relative" open={isAlertopen} onClose={handleClickCloseAlert}>
        {isLoading && <LoadingBox />}
        <AlertTitle>
          <Badge
            className="text-xs w-fit"
            color={
              authority.level === AuthorityLevel.SUPER_ADMIN
                ? 'pink'
                : authority.level === AuthorityLevel.ADMIN
                ? 'yellow'
                : 'zinc'
            }
          >
            {authority.level}
          </Badge>
          <p>{`'${authority.code}'을 삭제하시겠습니까?`}</p>
        </AlertTitle>
        <AlertDescription>노래 원본은 삭제되지 않습니다.</AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setAlertOpen(false)}>
            취소
          </Button>
          <Button onClick={handleClickDelete}>삭제</Button>
        </AlertActions>
      </Alert>
      <TrashIcon className="size-5 opacity-50 hover:opacity-100" onClick={() => setAlertOpen(true)} />
    </>
  );
};
