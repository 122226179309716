import { config } from '@/config';

export const fetcher = (url: string, init: any) =>
  fetch(`${config.api.host}${url}`, {
    ...init,
    headers: {
      authorization: `Bearer ${localStorage.getItem(config.token.name.access)}`,
    },
  })
    .then(res => res.json())
    .catch(e => console.log(e));
