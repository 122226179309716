import useSWR from 'swr';
import { VideoCameraIcon } from '@heroicons/react/24/solid';

import { MusicItem, ResWithSuccess } from '@/type';
import { ChangeEvent, useState } from 'react';
import { Button } from '@/component/basic/button';
import axios from 'axios';
import { config } from '@/config';
import { useToast } from '@/hook';

export function BosongSoPretty() {
  const { data, mutate } = useSWR<ResWithSuccess<MusicItem[]>>('/musics/unsets');

  return (
    <div className="flex flex-col gap-[1px] bg-zinc-400">
      {data?.data.map(item => (
        <UnsetMusicItem key={item.id} item={item} mutate={() => mutate()} />
      ))}
    </div>
  );
}

const UnsetMusicItem = ({ item, mutate }: { item: MusicItem; mutate: () => void }) => {
  const [file, setFile] = useState<File | undefined>();
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop();

      const newFileName = `${item.videoId}.${fileExtension}`;

      const newFile = new File([selectedFile], newFileName, {
        type: selectedFile.type,
        lastModified: selectedFile.lastModified,
      });
      setFile(newFile);
    }
  };

  const handleUploadMusic = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    setLoading(true);

    try {
      await axios.patch(`${config.api.host}/musics/${item.id}/audioUrl`, formData, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(config.token.name.access)}`,
        },
      });

      toast({ type: 'success', content: '업로드에 성공했습니다.' });
      mutate();
    } catch (error) {
      toast({ type: 'error', content: '업로드에 실패했습니다.' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-4 px-3 py-4 bg-white">
      <div className="flex flex-row items-center gap-4">
        <img src={item.thumbnailUrl} alt="" className="w-[50px] aspect-square" />
        <div>{item.title}</div>
      </div>
      <a className="w-fit" href={`https://www.youtube.com/watch?v=${item.videoId}`} target="_blank" rel="noreferrer">
        <VideoCameraIcon className="size-5" />
      </a>
      <input type="file" accept="audio/*" onChange={handleChangeInput} disabled={!!file || isLoading} />
      {!!file && (
        <Button onClick={handleUploadMusic} disabled={isLoading}>
          업로드
        </Button>
      )}
    </div>
  );
};
