import tw from 'tailwind-styled-components';
import { useParams } from 'react-router-dom';

import { LibraryMusicListContainer } from '@/container/music';

const Container = tw.div`
  relative
  flex flex-col
  w-full h-full
`;

const Style = {
  Container,
};

export function LibarayMusicListPage() {
  const { libraryId } = useParams();

  return <Style.Container>{libraryId && <LibraryMusicListContainer libraryId={libraryId} />}</Style.Container>;
}
