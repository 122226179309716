import { create } from 'zustand';

const KEY = 'yttp-like-list';

type LibraryLikeListStore = {
  likeList: number[];
  toggleLike: (id: number) => void;
  init: () => void;
};

export const useLibraryLikeListStore = create<LibraryLikeListStore>()((set, get) => ({
  likeList: [],
  toggleLike: (id: number) => {
    const { likeList } = get();
    const updatedLikeList = likeList.includes(id) ? likeList.filter(likeId => likeId !== id) : [...likeList, id];

    localStorage.setItem(KEY, JSON.stringify(updatedLikeList));
    set({ likeList: updatedLikeList });
  },
  init: () => {
    const yttpLikeList = localStorage.getItem(KEY);

    try {
      if (yttpLikeList) {
        const likeList = JSON.parse(yttpLikeList) as number[];
        set({ likeList });
      } else {
        localStorage.setItem(KEY, JSON.stringify([]));
      }
    } catch (error) {
      localStorage.setItem(KEY, JSON.stringify([]));
      set({ likeList: [] });
    }
  },
}));
