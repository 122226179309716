import useSWR from 'swr';
import tw from 'tailwind-styled-components';

import { CreateAuthContainer, UpdateAuthorityContainer } from '@/container/auth';
import { Badge } from '@/component/basic/badge';

import { AuthorityLevel, AuthorityModel, type ResWithSuccess } from '@/type';

const Container = tw.div`
  flex flex-col
  w-full h-full
`;

const ScrollContainer = tw.div`
  flex flex-col
  w-full flex-1
  overflow-y-scroll
  bg-zinc-100
  gap-[1px]
`;

export function ManagePage() {
  const { data, mutate } = useSWR<ResWithSuccess<AuthorityModel[]>>('/authority');

  return (
    <Container>
      <ScrollContainer>
        {data?.data.map(authority => (
          <UpdateAuthorityContainer key={authority.id} authority={authority} onSuccess={() => mutate()}>
            {({ openDialog }) => <AuthItem authority={authority} onClick={openDialog} />}
          </UpdateAuthorityContainer>
        ))}
      </ScrollContainer>
      `
      <div className="flex px-4 py-8">
        <CreateAuthContainer onSuccess={() => mutate()} />
      </div>
    </Container>
  );
}

const AuthItem = ({ authority, onClick }: { authority: AuthorityModel; onClick: () => void }) => {
  return (
    <div className="flex flex-row items-center justify-between w-full px-4 py-[20px] bg-white" onClick={onClick}>
      <div className="flex flex-row items-center gap-x-4">
        <div className="flex flex-row items-center w-[100px]">
          <Badge
            className="text-xs"
            color={
              authority.level === AuthorityLevel.SUPER_ADMIN
                ? 'pink'
                : authority.level === AuthorityLevel.ADMIN
                ? 'yellow'
                : 'zinc'
            }
          >
            {authority.level}
          </Badge>
        </div>
        <div className="flex flex-row items-center gap-2 text-sm">{authority.code}</div>
      </div>
      {authority.level === AuthorityLevel.VIEWER && <div className="text-xs">{authority.libraries.length}</div>}
    </div>
  );
};
