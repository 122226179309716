import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/component/basic/button';
import { Field, Label } from '@/component/basic/fieldset';
import { Input } from '@/component/basic/input';

const answer = [
  '김보송',
  '나',
  '보송공주님',
  '요기보송',
  '뵤숑',
  '보숑',
  '킹보숑',
  '퀸보송',
  '딸기모찌보송',
  '보숑냥이',
  '보숑냥냥이',
  '보숑냥아치',
  '존예보스보송',
];

export function EasterEggPage() {
  const navigate = useNavigate();
  const [input, setInput] = useState('');
  const [result, setResult] = useState('');
  const [isCorrect, setCorrect] = useState(false);

  const handleClickEnter = () => {
    if (answer.includes(input)) {
      setResult(`정답입니다.❤️ 세상에서 아름다운 사람은 '${input}' 입니다. ( ${answer.join(', ')} ) `);
      setCorrect(true);
    } else {
      setResult('틀렸어요... 모르면 바보😙');
    }
  };

  return (
    <div className="flex flex-col w-full justify-center items-center">
      <Field className="flex flex-col justify-center items-center w-full py-[200px] px-4 gap-4">
        <div className="text-xs text-zinc-400" onClick={() => navigate('/')}>
          뒤로가기
        </div>
        <Label>세상에서 제일 아름다운 사람은?</Label>
        <div className="bg-zinc-200 px-1">{result}</div>
        {!isCorrect ? (
          <>
            <Input
              name="full_name"
              value={input}
              onChange={e => setInput(e.target.value)}
              placeholder="정답을 입력해야 노래를 들을 수 있습니다^^"
            />
            <Button className="w-full" onClick={handleClickEnter}>
              입장하기
            </Button>
          </>
        ) : (
          <Button className="w-full" onClick={() => navigate('/musics')}>
            노래 들으러 가기
          </Button>
        )}
      </Field>
    </div>
  );
}
