import { LibraryItem, MusicItem } from '@/type';

export const debounce = (callback: any, delay: number = 400) => {
  let timer: NodeJS.Timeout;

  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback.apply(this, args), delay);
  };
};

export const extractYouTubeVideoId = (url: string): string | undefined => {
  const urlObj = new URL(url);
  let videoId = '';

  const siParam = urlObj.searchParams.get('si');
  const vParam = urlObj.searchParams.get('v');

  if (siParam) {
    videoId = urlObj.pathname.split('/')[1];
  } else if (vParam) {
    videoId = vParam;
  }
  return videoId;
};

export function parseTime(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const minutesString = minutes < 10 ? `0${minutes}` : minutes.toString();
  const secondsString = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds.toString();

  return `${minutesString}:${secondsString}`;
}

export const sortMusicItemByIdArray = (objects: MusicItem[], ids: number[]) => {
  const idToIndexMap = new Map<number, number>(ids.map((id, index) => [id, index]));

  return objects.sort((a, b) => {
    const indexA = idToIndexMap.get(a.id);
    const indexB = idToIndexMap.get(b.id);
    if (indexA !== undefined && indexB !== undefined) {
      return indexA - indexB;
    }
    return 0;
  });
};

export function isNumberArrayEqual(arr1: number[], arr2: number[]): boolean {
  if (arr1.length !== arr2.length) return false;

  const set1: Set<number> = new Set(arr1);
  const set2: Set<number> = new Set(arr2);

  if (set1.size !== set2.size) return false;

  for (const item of set1) {
    if (!set2.has(item)) return false;
  }

  return true;
}

export function sortLibraryItemByOrder(order: number[], items: LibraryItem[]): LibraryItem[] {
  const orderMap = new Map<number, number>();
  order.forEach((id, index) => orderMap.set(id, index));

  return items.sort((a, b) => {
    const indexA = orderMap.has(a.id) ? orderMap.get(a.id)! : Number.MAX_SAFE_INTEGER;
    const indexB = orderMap.has(b.id) ? orderMap.get(b.id)! : Number.MAX_SAFE_INTEGER;

    return indexA - indexB;
  });
}
