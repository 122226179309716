import { useEffect, useState } from 'react';
import useSWR from 'swr';
import tw from 'tailwind-styled-components';
import { EllipsisVerticalIcon } from '@heroicons/react/16/solid';
import { ReactSortable } from 'react-sortablejs';

import { useMusicStore } from '@/store';

import { DeleteMusicFromLibraryContainer, EditLibraryTitleContainer } from '@/container/music';
import { MusicScrollLayout } from '@/layout';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '@/component/basic/dropdown';
import { MusicListItem } from '@/component/custom';

import { AuthorityLevel, MuiscLibraryModel, type LibraryItem, type ResWithSuccess } from '@/type';
import axios from 'axios';
import { config } from '@/config';
import { useAuth } from '@/hook';

type Props = {
  libraryId: string;
};

const Container = tw.div`
  relative
  flex flex-col
  w-full h-full
`;

const findChangedOrderItems = (originalList: MuiscLibraryModel[], newList: MuiscLibraryModel[]) => {
  const changedItems: { id: number; order: number }[] = [];

  newList.forEach((newItem, newIndex) => {
    const originalItem = originalList.find(item => item.id === newItem.id);

    if (originalItem && originalItem.order !== newIndex + 1) {
      changedItems.push({
        id: newItem.id,
        order: newIndex + 1,
      });
    }
  });

  return changedItems;
};

export function LibraryMusicListContainer({ libraryId }: Props) {
  const { auth } = useAuth();
  const { setCurrentPlayList, selectedMusicIndex, setSelectedMusicIndex, setCurrentLibrary, currentLibrary } =
    useMusicStore();
  const { data, error, mutate } = useSWR<ResWithSuccess<LibraryItem>>(`/libraries/${libraryId}`);
  const [musicLibraryList, setMusicLibraryList] = useState<MuiscLibraryModel[]>([]);

  const handleSelectMusicItem = (musicIndex: number) => () => {
    if (!data?.data || selectedMusicIndex === musicIndex) return;

    setSelectedMusicIndex(musicIndex);
    setCurrentLibrary({
      title: `재생목록 > ${data.data.title}`,
      route: `/musics/libraries/${data.data.id}`,
    });
  };

  const handleSortMusicItems = async (newState: MuiscLibraryModel[]) => {
    const changedItems = findChangedOrderItems(musicLibraryList, newState);

    if (changedItems.length === 0) return;

    setMusicLibraryList(newState);

    try {
      await axios.patch(`${config.api.host}/musics-libraries/order`, {
        state: changedItems,
      });
      mutate();
    } catch (error) {
      console.error('Failed to update the order', error);
    }
  };

  useEffect(() => {
    if (data?.data) {
      const musicLibraryList = data.data.musicLibraries.sort((a, b) => a.order - b.order);
      setCurrentPlayList(musicLibraryList.map(musicLibrary => musicLibrary.music));
      setMusicLibraryList(musicLibraryList);
    }
  }, [data, setCurrentPlayList]);

  if (error) {
    return <div className="px-4 flex w-full h-full justify-center items-center text-sm">Failed to load 😂</div>;
  }

  if (!data?.data) {
    return <div className="px-4 flex w-full h-full justify-center items-center text-sm">loading</div>;
  }

  return (
    <Container>
      {data.data && (
        <EditLibraryTitleContainer
          libraryId={Number(libraryId)}
          libraryTitle={data.data.title}
          update={() => mutate()}
        />
      )}
      <MusicScrollLayout>
        {musicLibraryList.length ? (
          <ReactSortable
            list={musicLibraryList}
            setList={handleSortMusicItems}
            delay={500}
            delayOnTouchOnly={true}
            disabled={auth?.authority.level !== AuthorityLevel.SUPER_ADMIN}
          >
            {musicLibraryList
              .map(musicLibrary => musicLibrary.music)
              .map((musicItem, i) => (
                <MusicListItem
                  key={musicItem.videoId}
                  isSelected={currentLibrary?.route === `/musics/libraries/${libraryId}` && i === selectedMusicIndex}
                  item={musicItem}
                  libraries={
                    <div className="flex flex-row gap-4">
                      {musicLibraryList
                        .filter(musicLibrary => musicLibrary.music.id === musicItem.id)
                        .map(musicLibrary => (
                          <div
                            key={musicLibrary.id}
                            className="flex flex-row gap-1 items-center rounded-lg px-1"
                            style={{ background: musicLibrary.library.color }}
                          >
                            <div className="text-[10px] text-white">{musicLibrary.library.title}</div>
                          </div>
                        ))}
                    </div>
                  }
                  onClickItem={handleSelectMusicItem(i)}
                  dropDownMenu={
                    <Dropdown>
                      <DropdownButton plain aria-label="More options" className="w-[30px] h-[30px]">
                        <EllipsisVerticalIcon />
                      </DropdownButton>
                      <DropdownMenu>
                        <DropdownItem>
                          <DeleteMusicFromLibraryContainer
                            musicLibraryId={
                              data.data.musicLibraries.find(musicLibrary => musicLibrary.music.id === musicItem.id)?.id!
                            }
                            onSuccess={() => mutate()}
                          />
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  }
                />
              ))}
          </ReactSortable>
        ) : (
          <div className="px-4 h-full flex justify-center items-center text-sm">
            재생목록이 없습니다. 재생목록을 추가해주세요❤️
          </div>
        )}
      </MusicScrollLayout>
    </Container>
  );
}
