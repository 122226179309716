import { ReactNode } from 'react';
import tw from 'tailwind-styled-components';

import { useMusicStore } from '@/store';

type Props = {
  children: ReactNode;
};

const Container = tw.div<{ $isAudioOpened: boolean; $isAudiodetailOpened: boolean }>`
  relative
  flex flex-col
  w-full flex-1
  overflow-y-scroll
  mt-[5px]
 ${({ $isAudioOpened, $isAudiodetailOpened }) =>
   $isAudiodetailOpened ? 'mb-[225px]' : $isAudioOpened ? 'mb-[125px]' : 'mb-0'}
`;

export function MusicScrollLayout({ children }: Props) {
  const { selectedMusicIndex, isDetailClicked } = useMusicStore();

  return (
    <Container $isAudioOpened={selectedMusicIndex !== null} $isAudiodetailOpened={isDetailClicked}>
      {children}
    </Container>
  );
}
