export interface ResWithSuccess<T> {
  data: T;
}

export interface ResWithError {
  message: string;
  error: string;
  statusCode: number;
}

export type Res<T> = ResWithSuccess<T> | ResWithError;

export interface YoutubeInfo {
  author_name: string;
  thumbnail_url: string;
  title: string;
  width: number;
  height: number;
  url: string;
}

export interface MusicItem {
  id: number;
  title: string;
  author: string;
  thumbnailUrl: string;
  videoId: string;
  audioUrl?: string;
  adminPlayCount: number;
  viewerPlayCount: number;
  musicLibraries: MuiscLibraryModel[];
  updatedAt: Date;
  createdAt: Date;
}

export interface CreateMusicBody {
  title: string;
  author: string;
  thumbnailUrl: string;
  videoId: string;
}

export interface CreateLibraryBody {
  title: string;
}

export interface CreateAdminBody {
  name: string;
}

export type LibraryState = {
  title: string;
  route: string;
};

export interface MuiscLibraryModel {
  id: number;
  music: MusicItem;
  library: LibraryItem;
  order: number;
}

export interface LibraryItem {
  id: number;
  title: string;
  color: string;
  musicLibraries: MuiscLibraryModel[];
  createdAt: Date;
}

export enum AuthorityLevel {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  VIEWER = 'VIEWER',
}

export interface PatchMusicToLibraryBody {
  libraryId: number;
  musicId: number;
  isChecked: boolean;
}

export interface CreateAuthBodyDTO {
  code: string;
  level: AuthorityLevel;
  libraryIds: number[];
}

export interface UpdateAuthorityBodyDTO {
  code?: string;
  libraryIds?: number[];
}

export interface AuthorityModel {
  id: number;
  code: string;
  level: AuthorityLevel;
  libraries: LibraryItem[];
}

export interface AuthModel {
  id: number;
  uuid: string;
  authority: AuthorityModel;
}
