import { MouseEventHandler, ReactNode } from 'react';
import { SpeakerWaveIcon } from '@heroicons/react/16/solid';

import { useAuth } from '@/hook';

import { AuthorityLevel, type MusicItem } from '@/type';

type Props = {
  isSelected: boolean;
  item: MusicItem;
  dropDownMenu: ReactNode;
  libraries?: ReactNode;
  onClickItem: () => void;
};

export function MusicListItem({ isSelected, item, dropDownMenu, libraries, onClickItem }: Props) {
  const { auth } = useAuth();

  const handleClickItem: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();

    if (!item.audioUrl) return;
    onClickItem();
  };

  return (
    <div
      className={`select-none flex flex-row items-center px-4 py-3 gap-4 ${
        item.audioUrl ? 'opacity-100' : 'opacity-20'
      } ${isSelected ? 'bg-zinc-200' : 'bg-white'}`}
    >
      <div className="relative">
        {isSelected && (
          <div className="absolute top-0 left-0 w-[50px] h-[50px] bg-white/30 flex justify-center items-center">
            <SpeakerWaveIcon className="size-6 animate-pulse" />
          </div>
        )}
        <img src={item.thumbnailUrl} alt="" className="w-[50px] aspect-square" />
      </div>
      <div className="flex flex-col justify-center gap-1 flex-1 h-full" onClick={handleClickItem}>
        {auth?.authority.level === 'SUPER_ADMIN' && libraries}
        <div className="w-full truncate-multiline text-xs font-semibold">{item.title}</div>
        <div className="flex flex-row items-center gap-2 w-full text-xs text-zinc-400">
          {item.author}
          {auth?.authority.level === AuthorityLevel.SUPER_ADMIN && (
            <p className="text-[10px]">
              {item.adminPlayCount.toLocaleString()} / {item.viewerPlayCount.toLocaleString()}
            </p>
          )}
        </div>
      </div>
      {dropDownMenu}
    </div>
  );
}
