import { create } from 'zustand';

import type { LibraryState, MusicItem } from '@/type';

type MusicStore = {
  currentLibrary: LibraryState | null;
  setCurrentLibrary: (library: LibraryState) => void;
  currentPlayList: MusicItem[];
  setCurrentPlayList: (items: MusicItem[]) => void;
  selectedMusicIndex: number | null;
  setSelectedMusicIndex: (index: number | null) => void;
  isDetailClicked: boolean;
  setIsDetailClicked: (isClicked: boolean) => void;
  initMusicStore: () => void;
};

export const useMusicStore = create<MusicStore>()((set, get) => ({
  currentLibrary: null,
  setCurrentLibrary: (currentLibrary: LibraryState | null) => set({ currentLibrary }),
  currentPlayList: [],
  setCurrentPlayList: (currentPlayList: MusicItem[]) => set({ currentPlayList }),
  selectedMusicIndex: null,
  setSelectedMusicIndex: (selectedMusicIndex: number | null) => set({ selectedMusicIndex }),
  isDetailClicked: false,
  setIsDetailClicked: (isDetailClicked: boolean) => set({ isDetailClicked }),
  initMusicStore: () =>
    set({
      currentLibrary: null,
      currentPlayList: [],
      selectedMusicIndex: null,
      isDetailClicked: false,
    }),
}));
