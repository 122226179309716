import { ReactNode, useState } from 'react';
import axios from 'axios';

import { config } from '@/config';
import { useAuth, useToast } from '@/hook';

import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/component/basic/dialog';
import { Field } from '@/component/basic/fieldset';
import { Input } from '@/component/basic/input';
import { Button } from '@/component/basic/button';
import { LoadingBox } from '@/component/custom';

import { ResWithSuccess } from '@/type';

type LoginAuthBodyDTO = {
  code: string;
  uuid?: string;
};

type LoginAuthResponseDTO = {
  accessToken: string;
  uuid: string;
};

type Props = {
  children: ({ openDialog }: { openDialog: () => void }) => ReactNode;
};

export function EnterDialogContainer({ children }: Props) {
  const { login } = useAuth();
  const toast = useToast();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [input, setInput] = useState('');

  const handleClickLogin = async () => {
    try {
      setLoading(true);

      const uuid = localStorage.getItem('yogattpeople-uuid') ?? undefined;

      const body: LoginAuthBodyDTO = {
        code: input,
        uuid,
      };
      const { data } = await axios.post<ResWithSuccess<LoginAuthResponseDTO>>(`${config.api.host}/auth/login`, body);

      login(data.data.accessToken, data.data.uuid);

      setDialogOpen(false);
    } catch (error) {
      toast({ content: '올바른 코드가 아니에요...🥲', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={isDialogOpen} onClose={() => {}} className="relative">
        {isLoading && <LoadingBox />}
        <DialogTitle>입장코드를 입력해주세요.</DialogTitle>
        <DialogBody>
          <Field>
            <Input value={input} onChange={e => setInput(e.target.value)} />
          </Field>
        </DialogBody>
        <DialogActions>
          <Button disabled={isLoading} plain onClick={() => setDialogOpen(false)}>
            취소
          </Button>
          <Button disabled={isLoading} onClick={handleClickLogin}>
            입장
          </Button>
        </DialogActions>
      </Dialog>
      {children({
        openDialog: () => setDialogOpen(true),
      })}
    </>
  );
}
