import { useState } from 'react';
import axios from 'axios';

import { config } from '@/config';
import { useToast } from '@/hook';

import { Button } from '@/component/basic/button';
import { Field, Label } from '@/component/basic/fieldset';
import { Input } from '@/component/basic/input';
import { Alert } from '@/component/basic/alert';
import { LoadingBox } from '@/component/custom';

import type { ResWithSuccess, CreateLibraryBody, LibraryItem } from '@/type';

type Props = {
  onSuccess?: (libraryItem: LibraryItem) => void;
};

export function CreateLibraryContainer({ onSuccess }: Props) {
  const toast = useToast();
  const [isButtonClicked, setButtonClicked] = useState(false);
  const [libraryTitle, setLibraryTitle] = useState('');
  const [isLoading, setLoading] = useState(false);

  const createLibrary = async () => {
    setLoading(true);

    const createLibraryBody: CreateLibraryBody = {
      title: libraryTitle,
    };

    try {
      const { data } = await axios.post<ResWithSuccess<LibraryItem>>(
        `${config.api.host}/musics/libraries`,
        createLibraryBody,
        {
          withCredentials: true,
        }
      );
      onSuccess?.(data.data);
      handleClickClose();
      toast({ content: '성공적으로 생성되었습니다.', type: 'success' });
    } catch (error) {
      toast({ content: '생성에 실패하였습니다.', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleClickClose = () => {
    if (isLoading) return;
    setButtonClicked(false);
    setLibraryTitle('');
  };

  return (
    <>
      <Alert open={isButtonClicked} onClose={handleClickClose}>
        <Field className="relative flex flex-col gap-4 z-[99] bg-zinc-50 p-2 rounded-lg">
          {isLoading && <LoadingBox />}
          <Label>재생 목록 생성하기</Label>
          <Input
            value={libraryTitle}
            onChange={e => setLibraryTitle(e.target.value)}
            placeholder="재생목록의 이름을 입력해주세요"
            disabled={isLoading}
          />
          <Button disabled={!libraryTitle || isLoading} className="w-full" onClick={createLibrary}>
            생성하기
          </Button>
        </Field>
      </Alert>
      <Button className="w-full" onClick={() => setButtonClicked(true)}>
        + 재생목록 생성하기
      </Button>
    </>
  );
}
