import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactNode, useEffect, useState } from 'react';
import { MusicalNoteIcon, ListBulletIcon, UsersIcon } from '@heroicons/react/16/solid';

import { useAuth } from '@/hook';
import { AuthorityLevel } from '@/type';

const menus = [
  {
    route: '/musics',
    icon: <MusicalNoteIcon />,
  },
  {
    route: '/musics/libraries',
    icon: <ListBulletIcon />,
  },
  {
    route: '/musics/manage',
    icon: <UsersIcon />,
  },
];

export function MusicLayout() {
  const { auth } = useAuth();
  const location = useLocation();
  const { libraryId } = useParams();
  const navigate = useNavigate();
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(
    libraryId ? -1 : menus.findIndex(menu => location.pathname === menu.route)
  );

  const handleClickLogo = () => navigate('/');

  const onSelectMenu = (index: number, route: string) => () => {
    setSelectedMenuIndex(index);
    navigate(route);
  };

  useEffect(() => {
    setSelectedMenuIndex(libraryId ? -1 : menus.findIndex(menu => location.pathname === menu.route));
  }, [libraryId]);

  return (
    <>
      <div className="flex flex-row items-center w-full h-[60px] shadow-sm bg-white px-4">
        <div className="flex flex-row items-center gap-4 flex-1">
          <img src="/logo.jpeg" className="w-[40px] h-[40px] p-[2px]" alt="" onClick={handleClickLogo} />
        </div>
        {
          <div className="flex flex-row justify-end gap-2 flex-1">
            {menus.map((menu, i) => {
              if (
                auth?.authority.level !== AuthorityLevel.SUPER_ADMIN &&
                (menu.route === '/musics/manage' || menu.route === '/musics')
              ) {
                return null;
              }

              return (
                <MenuButton
                  key={menu.route}
                  isSelected={selectedMenuIndex === i}
                  icon={menu.icon}
                  selectMenu={onSelectMenu(i, menu.route)}
                />
              );
            })}
          </div>
        }
      </div>
      <div className="w-full flex-1 overflow-hidden">
        <Outlet />
      </div>
    </>
  );
}

const MenuButton = ({
  isSelected,
  icon,
  selectMenu,
}: {
  isSelected: boolean;
  icon: ReactNode;
  selectMenu: () => void;
}) => {
  return (
    <div className={`${isSelected ? 'opacity-100' : 'opacity-30'} w-[30px] p-1 cursor-pointer`} onClick={selectMenu}>
      {icon}
    </div>
  );
};
