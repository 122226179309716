import { create } from 'zustand';

import { AuthModel } from '@/type';

interface AuthStore {
  auth?: AuthModel | null;
  setAuth: (auth: AuthModel | null) => void;
}

export const useAuthStore = create<AuthStore>(set => ({
  auth: undefined,
  setAuth: auth => set({ auth }),
}));
