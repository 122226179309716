import { ReactNode, useState } from 'react';
import { ArrowPathIcon } from '@heroicons/react/16/solid';
import axios from 'axios';

import { config } from '@/config';
import { useToast } from '@/hook';

import { DeleteAuthorityContainer } from '@/container/auth/DeleteAuthorityContainer';
import { SelectLibraryContainer } from '@/container/library';

import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/component/basic/dialog';
import { Input } from '@/component/basic/input';
import { Badge } from '@/component/basic/badge';
import { Button } from '@/component/basic/button';
import { LoadingBox } from '@/component/custom';

import { AuthorityLevel, ResWithSuccess, AuthorityModel, UpdateAuthorityBodyDTO } from '@/type';
import { isNumberArrayEqual } from '@/lib/utils';

export const UpdateAuthorityContainer = ({
  authority,
  onSuccess,
  children,
}: {
  authority: AuthorityModel;
  onSuccess: () => void;
  children: ({ openDialog }: { openDialog: () => void }) => ReactNode;
}) => {
  const toast = useToast();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [code, setCode] = useState<string>(authority.code);
  const [libraryIds, setLibraryIds] = useState<number[]>(authority.libraries.map(library => library.id));
  const isChanged =
    authority.code !== code ||
    !isNumberArrayEqual(
      authority.libraries.map(library => library.id),
      libraryIds
    );

  const handleClickReset = () => {
    setCode(authority.code);
    setLibraryIds(authority.libraries.map(library => library.id));
  };

  const handleClickClose = () => {
    handleClickReset();
    setDialogOpen(false);
    setLibraryIds(authority.libraries.map(library => library.id));
  };

  const handleClickEdit = async () => {
    setLoading(true);

    const body: UpdateAuthorityBodyDTO = {
      code,
      libraryIds,
    };

    try {
      const { data } = await axios.patch<ResWithSuccess<AuthorityModel>>(
        `${config.api.host}/authority/${authority.id}`,
        body,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem(config.token.name.access)}`,
          },
        }
      );

      if (data.data) {
        onSuccess();
        setDialogOpen(false);
        toast({ content: '수정 성공하였습니다.', type: 'success' });
      }
    } catch (error) {
      toast({ content: '수정 실패하였습니다.', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={isDialogOpen} onClose={() => {}} className="relative">
        {isLoading && <LoadingBox />}
        <DialogTitle>
          <div className="flex flex-row justify-between">
            <Badge
              className="text-xs w-fit"
              color={
                authority.level === AuthorityLevel.SUPER_ADMIN
                  ? 'pink'
                  : authority.level === AuthorityLevel.ADMIN
                  ? 'yellow'
                  : 'zinc'
              }
            >
              {authority.level}
            </Badge>
            <div className="flex flex-row gap-x-4">
              <ArrowPathIcon
                className={`size-5 ${isChanged ? 'opacity-100' : 'opacity-50'}`}
                onClick={handleClickReset}
              />
              {authority.level !== AuthorityLevel.SUPER_ADMIN && (
                <DeleteAuthorityContainer authority={authority} onSuccess={onSuccess} />
              )}
            </div>
          </div>
        </DialogTitle>
        <DialogBody className="flex flex-col gap-y-4">
          <Input
            disabled={code === '존예보송공주님'}
            className={`rounded-lg border ${code === authority.code ? 'border-white' : 'border-yellow-200'}`}
            value={code}
            onChange={e => setCode(e.target.value)}
          />
          {authority.level === AuthorityLevel.VIEWER && (
            <SelectLibraryContainer selectedLibraryIds={libraryIds} onSelectDone={setLibraryIds} />
          )}
        </DialogBody>
        <DialogActions>
          <Button disabled={isLoading} plain onClick={handleClickClose}>
            취소
          </Button>
          <Button disabled={isLoading || !isChanged} onClick={handleClickEdit}>
            수정
          </Button>
        </DialogActions>
      </Dialog>
      {children({
        openDialog: () => setDialogOpen(true),
      })}
    </>
  );
};
