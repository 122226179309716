function required(key: string, defaultValue?: string) {
  const value = process.env[key] || defaultValue;

  if (value === undefined || value === null) {
    throw new Error(`Missing env.${key}`);
  }
  return value;
}

export const config = {
  client: {
    host: required('REACT_APP_CLIENT_URL'),
  },
  api: {
    host: required('REACT_APP_API_URL'),
  },
  token: {
    name: {
      access: required('REACT_APP_ACCESS_TOKEN_NAME'),
    },
  },
};
