import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@/hook';
import { AuthorityLevel } from '@/type';

type Props = {
  children: ReactNode;
  authorities: AuthorityLevel[];
};

export function AuthLayout({ children, authorities }: Props) {
  const navigate = useNavigate();
  const { auth, error } = useAuth();

  useEffect(() => {
    if (!auth || (auth && !authorities.includes(auth.authority.level))) {
      navigate('/');
    }
  }, [auth, error, navigate, authorities]);

  return <>{children}</>;
}
