import { ChangeEvent, useCallback, useState } from 'react';
import axios from 'axios';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import tw from 'tailwind-styled-components';

import { debounce, extractYouTubeVideoId } from '@/lib/utils';
import { config } from '@/config';
import { useToast } from '@/hook';
import { useMusicStore } from '@/store';

import { Input, InputGroup } from '@/component/basic/input';
import { InfoBox, LoadingBox, YoutubeInfoItem } from '@/component/custom';

import type { CreateMusicBody, MusicItem, ResWithSuccess, YoutubeInfo } from '@/type';

const Container = tw.div`
  flex flex-col gap-2
  px-4 py-2
`;

const InputContainer = tw.div`
  relative
  w-full h-fit
`;

const ResultContainer = tw.div`
  relative w-full h-[40px]
  bg-zinc-100
  rounded-md
`;

const YoutubeItemContainer = tw.div`
  absolute top-0 right-0 left-0
  flex flex-col
  p-2
  rounded-md overflow-hidden
  bg-zinc-100
  z-[998]
`;

const Style = {
  Container,
  InputContainer,
  ResultContainer,
  YoutubeItemContainer,
};

type Props = {
  onSuccessPostMusic: (musicItem: MusicItem) => void;
};

export function GetAndPostYoutubeMusicContainer({ onSuccessPostMusic }: Props) {
  const { selectedMusicIndex, initMusicStore } = useMusicStore();
  const toast = useToast();
  const [input, setInput] = useState('');
  const [foundYoutubeInfo, setFoundYoutubeInfo] = useState<YoutubeInfo | null>();
  const [isLoading, setLoading] = useState(false);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    setInput(url);
    getYoutubeInfo(url);
  };

  const closeYoutubeItem = () => {
    setInput('');
    setFoundYoutubeInfo(undefined);
  };

  const getYoutubeInfo = useCallback(
    debounce(async (url: string) => {
      setLoading(true);

      if (url === '') {
        setLoading(false);
        setFoundYoutubeInfo(undefined);
        return;
      }

      try {
        const { data } = await axios.get<YoutubeInfo>(`https://www.youtube.com/oembed?url=${url}&format=json`);
        setFoundYoutubeInfo({ ...data, url });
      } catch (error) {
        setFoundYoutubeInfo(null);
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  const postMusic = async () => {
    if (!foundYoutubeInfo) return;

    const videoId = extractYouTubeVideoId(foundYoutubeInfo.url);

    if (!videoId) return;
    setLoading(true);

    const createMusicBody: CreateMusicBody = {
      title: foundYoutubeInfo.title,
      author: foundYoutubeInfo.author_name,
      thumbnailUrl: foundYoutubeInfo.thumbnail_url,
      videoId,
    };

    try {
      const { data } = await axios.post<ResWithSuccess<MusicItem>>(`${config.api.host}/musics`, createMusicBody, {
        withCredentials: true,
      });

      if (selectedMusicIndex !== null) {
        initMusicStore();
      }
      onSuccessPostMusic(data.data);
      const musicList = localStorage.getItem('musicList');

      if (musicList) {
        const parsedMusicList = JSON.parse(musicList);
        parsedMusicList['all'] = [data.data.id, ...parsedMusicList['all']];

        localStorage.setItem('musicList', JSON.stringify(parsedMusicList));
      }
      closeYoutubeItem();
      toast({ content: '업로드 성공', type: 'success' });
    } catch (error) {
      toast({ content: '업로드 실패', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Style.Container>
      <Style.InputContainer>
        <InputGroup>
          <MagnifyingGlassIcon />
          <Input
            name="search"
            placeholder="Search&hellip;"
            aria-label="Search"
            value={input}
            onChange={handleChangeInput}
            disabled={isLoading}
          />
        </InputGroup>
      </Style.InputContainer>
      <Style.ResultContainer>
        <InfoBox info={foundYoutubeInfo === null ? '일치하는 동영상이 없습니다🥹' : '유튜브 링크를 입력하세요🥰'} />
        {foundYoutubeInfo && (
          <Style.YoutubeItemContainer>
            {isLoading && <LoadingBox />}
            <YoutubeInfoItem youtubeInfo={foundYoutubeInfo} closeItem={closeYoutubeItem} postMusic={postMusic} />
          </Style.YoutubeItemContainer>
        )}
      </Style.ResultContainer>
    </Style.Container>
  );
}
