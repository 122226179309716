import React, { useEffect, useState } from 'react';

import { Alert, AlertActions, AlertBody } from '@/component/basic/alert';
import { Button } from '@/component/basic/button';
import { ArrowUpTrayIcon } from '@heroicons/react/16/solid';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

export const PWAInstallPromptContainer = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isGuidOpen, setGuidOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleBeforeInstallPrompt = (event: Event) => {
    const installApp = localStorage.getItem('install-app');

    if (installApp && installApp === 'false') {
      return;
    }
    event.preventDefault();
    setDeferredPrompt(event);
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then((choiceResult: { outcome: string }) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('사용자가 설치 프롬프트에 동의했습니다.');
        } else {
          console.log('사용자가 설치 프롬프트를 무시했습니다.');
        }

        setDeferredPrompt(null);
      });
    }
  };

  const handleClickNoInstall = (useMemory: boolean) => () => {
    if (useMemory) {
      localStorage.setItem('install-app', 'false');
      setGuidOpen(true);
    }
    setDeferredPrompt(null);
  };

  return (
    <React.Fragment>
      <Alert open={isGuidOpen} onClose={() => setGuidOpen(false)}>
        <AlertBody className="flex flex-col items-center justify-center gap-4">
          <img className="border border-zinc-200 rounded-lg w-[50px] h-[50px]" src="logo.jpeg" alt="로고" />
          <div>언제든지 앱으로 추가할 수있습니다:)</div>
          <div className="flex flex-col gap-2 text-sm p-3 bg-zinc-200 rounded-lg">
            <div className="flex flex-row items-center gap-2">
              <p className="text-base">ios:</p> <ArrowUpTrayIcon className="size-5" /> (공유 버튼) - 홈 화면에 추가
            </div>
            <div className="flex flex-row items-center gap-2">
              <p className="text-base">android:</p>{' '}
              <EllipsisVerticalIcon className="size-5 border border-black rounded-full" /> (더보기 버튼) - 홈 화면에
              추가
            </div>
          </div>
        </AlertBody>
      </Alert>
      <Alert open={!!deferredPrompt} onClose={() => setDeferredPrompt(null)}>
        <AlertBody className="flex flex-col items-center justify-center gap-4">
          <img className="border border-zinc-200 rounded-lg w-[50px] h-[50px]" src="logo.jpeg" alt="로고" />
          <div>앱으로 보시겠어요?</div>
        </AlertBody>
        <AlertActions className="flex gap-2 w-full">
          <div className="flex w-full justify-center text-sm mt-4 text-zinc-500" onClick={handleClickNoInstall(true)}>
            다시 보지 않기
          </div>
          <div className="flex flex-row w-full gap-x-2">
            <Button className="flex-1" onClick={handleInstallClick}>
              앱 설치하기
            </Button>
            <Button className="flex-1" outline onClick={handleClickNoInstall(false)}>
              닫기
            </Button>
          </div>
        </AlertActions>
      </Alert>
    </React.Fragment>
  );
};
