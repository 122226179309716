type Props = {
  info: string;
};

export function InfoBox({ info }: Props) {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <p className="text-xs">{info}</p>
    </div>
  );
}
