import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useMusicStore } from '@/store';

import { AudioPlayer } from '@/component/custom';

export function AudioPlayerContainer() {
  const {
    currentPlayList,
    selectedMusicIndex,
    setSelectedMusicIndex,
    isDetailClicked,
    setIsDetailClicked,
    initMusicStore,
    currentLibrary,
  } = useMusicStore();
  const { pathname } = useLocation();

  useEffect(() => {
    return () => initMusicStore();
  }, [initMusicStore]);

  if (selectedMusicIndex === null) {
    return <div className="w-full h-[20px] bg-white" />;
  }

  return (
    currentLibrary && (
      <AudioPlayer
        currentLibrary={currentLibrary}
        currentPlayList={currentPlayList}
        selectedMusicIndex={selectedMusicIndex}
        isDetailClicked={isDetailClicked}
        isInvisible={pathname === '/'}
        setIsDetailClicked={setIsDetailClicked}
        setSelectedMusicIndex={setSelectedMusicIndex}
        closePlayer={initMusicStore}
      />
    )
  );
}
