import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TrashIcon } from '@heroicons/react/16/solid';

import { config } from '@/config';
import { useToast } from '@/hook';

import { Loading } from '@/component/custom';
import { Alert, AlertActions, AlertDescription, AlertTitle } from '@/component/basic/alert';
import { Button } from '@/component/basic/button';

import type { ResWithSuccess } from '@/type';

type Props = {
  libraryId: number;
  libraryTitle: string;
};

export function DeleteLibraryContainer({ libraryId, libraryTitle }: Props) {
  const navigate = useNavigate();
  const toast = useToast();
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleClickDeleteLibrary = async () => {
    setLoading(true);

    try {
      const { data } = await axios.delete<ResWithSuccess<boolean>>(`${config.api.host}/musics/libraries/${libraryId}`);

      if (data.data) {
        toast({ content: '삭제 하였습니다.', type: 'success' });

        const sortMusicMap = localStorage.getItem('musicList');

        if (sortMusicMap) {
          const musicMap = JSON.parse(sortMusicMap);

          delete musicMap[libraryId];

          localStorage.setItem('musicList', JSON.stringify(musicMap));
        }

        setAlertOpen(false);
        navigate('/musics/libraries');
      }
    } catch (error) {
      toast({ content: '삭제에 실패했습니다', type: 'error' });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Alert className="relative" open={isAlertOpen} onClose={setAlertOpen}>
        {isLoading ? (
          <div className="absolute top-0 right-0 bottom-0 left-0 bg-white/05 backdrop-blur-[2px] rounded-2xl flex justify-center items-center z-[999]">
            <Loading />
          </div>
        ) : null}
        <AlertTitle>{`재생목록 '${libraryTitle}'을 삭제하시겠습니까?`}</AlertTitle>
        <AlertDescription>노래 원본은 삭제되지 않습니다.</AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setAlertOpen(false)}>
            취소
          </Button>
          <Button onClick={handleClickDeleteLibrary}>삭제</Button>
        </AlertActions>
      </Alert>
      <TrashIcon className="size-5 opacity-50 hover:opacity-100" onClick={() => setAlertOpen(true)} />
    </>
  );
}
