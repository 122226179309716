import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { SWRConfig } from 'swr';

import { fetcher } from '@/lib/fetcher';

import { AuthLayout, MusicLayout, RootLayout } from '@/layout';
import {
  LandingPage,
  EasterEggPage,
  LibarayMusicListPage,
  LibraryListsPage,
  MusicListPage,
  ManagePage,
  BosongSoPretty,
} from '@/page';

import './index.css';
import { AuthorityLevel } from '@/type';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <SWRConfig
      value={{
        fetcher,
      }}
    >
      <Routes>
        <Route path="/" element={<RootLayout />}>
          <Route index element={<LandingPage />} />
          <Route path="musics" element={<MusicLayout />}>
            <Route
              index
              element={
                <AuthLayout authorities={[AuthorityLevel.SUPER_ADMIN]}>
                  <MusicListPage />
                </AuthLayout>
              }
            />
            <Route path="libraries">
              <Route
                index
                element={
                  <AuthLayout authorities={[AuthorityLevel.SUPER_ADMIN, AuthorityLevel.ADMIN, AuthorityLevel.VIEWER]}>
                    <LibraryListsPage />
                  </AuthLayout>
                }
              />
              <Route
                path=":libraryId"
                element={
                  <AuthLayout authorities={[AuthorityLevel.SUPER_ADMIN, AuthorityLevel.ADMIN, AuthorityLevel.VIEWER]}>
                    <LibarayMusicListPage />
                  </AuthLayout>
                }
              />
            </Route>
            <Route
              path="manage"
              index
              element={
                <AuthLayout authorities={[AuthorityLevel.SUPER_ADMIN]}>
                  <ManagePage />
                </AuthLayout>
              }
            />
          </Route>
          <Route
            path="easter-egg"
            index
            element={
              <AuthLayout authorities={[AuthorityLevel.SUPER_ADMIN]}>
                <EasterEggPage />
              </AuthLayout>
            }
          />
          <Route path="bosong-so-pretty" index element={<BosongSoPretty />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </SWRConfig>
  </BrowserRouter>
);
