import { useState } from 'react';
import axios from 'axios';

import { config } from '@/config';
import { useToast } from '@/hook';

import { SelectLibraryContainer } from '@/container/library';

import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/component/basic/dialog';
import { Button } from '@/component/basic/button';
import { Input } from '@/component/basic/input';
import { LoadingBox } from '@/component/custom';
import { Select } from '@/component/basic/select';

import { type ResWithSuccess, type AuthModel, type CreateAuthBodyDTO, AuthorityLevel } from '@/type';

type Props = {
  onSuccess: () => void;
};

export function CreateAuthContainer({ onSuccess }: Props) {
  const toast = useToast();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [level, setLevel] = useState(AuthorityLevel.VIEWER);
  const [libraryIds, setLibraryIds] = useState<number[]>([]);

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueWithoutSpaces = e.target.value.replace(/\s+/g, '');
    setCode(valueWithoutSpaces);
  };

  const handleClickClose = () => {
    setCode('');
    setLevel(AuthorityLevel.VIEWER);
    setLibraryIds([]);
    setDialogOpen(false);
  };

  const createLibrary = async () => {
    setLoading(true);

    const createAuthBody: CreateAuthBodyDTO = {
      code,
      level,
      libraryIds,
    };

    try {
      const { data } = await axios.post<ResWithSuccess<AuthModel>>(`${config.api.host}/auth`, createAuthBody, {
        headers: {
          authorization: `Bearer ${localStorage.getItem(config.token.name.access)}`,
        },
      });

      if (data.data) {
        toast({ content: '성공적으로 생성되었습니다.', type: 'success' });
        setDialogOpen(false);
        onSuccess();
      }
    } catch (error) {
      toast({ content: '생성에 실패하였습니다.', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={isDialogOpen} onClose={() => {}} className="relative">
        {isLoading && <LoadingBox />}
        <DialogTitle>입장코드 생성하기</DialogTitle>
        <DialogBody className="flex flex-col gap-4">
          <Input value={code} onChange={handleChangeInput} placeholder="입장코드를 입력해주세요" />
          <Select name="level" value={level} onChange={e => setLevel(e.target.value as AuthorityLevel)}>
            <option value={AuthorityLevel.SUPER_ADMIN}>{AuthorityLevel.SUPER_ADMIN}</option>
            <option value={AuthorityLevel.ADMIN}>{AuthorityLevel.ADMIN}</option>
            <option defaultChecked value={AuthorityLevel.VIEWER}>
              {AuthorityLevel.VIEWER}
            </option>
          </Select>
          {level === AuthorityLevel.VIEWER && (
            <SelectLibraryContainer selectedLibraryIds={libraryIds} onSelectDone={setLibraryIds} />
          )}
        </DialogBody>
        <DialogActions>
          <Button disabled={isLoading} plain onClick={handleClickClose}>
            취소
          </Button>
          <Button disabled={isLoading || code === ''} className="w-full" onClick={createLibrary}>
            입장코드 생성하기
          </Button>
        </DialogActions>
      </Dialog>
      <Button className="w-full" onClick={() => setDialogOpen(true)}>
        + 입장코드 생성하기
      </Button>
    </>
  );
}
